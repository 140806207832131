// Importa a biblioteca
import { Notyf } from "notyf";

let notyf = new Notyf({
  dismissible: true,
  duration: 2700,
  types: [
    {
      type: "primary",
      background: "#009ef7",
    },
    {
      type: "success",
      background: "#50CD89",
    },
    {
      type: "info",
      background: "#7239ea",
    },
    {
      type: "danger",
      background: "#F1416C",
    },
    {
      type: "dark",
      background: "#181c32",
    },
    {
      type: "warning",
      background: "#ffc700",
    },
  ],
});

// Configura a biblioteca
let Notification = {
  show: (type, message, x, y) => {
    notyf.open({
      type: type,
      message: message,
      position: {
        x: x,
        y: y,
      },
    });
  },
};

// Seta globalmente no documento
document.Notification = Notification;
